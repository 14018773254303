.header {
  position: fixed;
  top: 0;
  left: 0px;
  z-index: 90;
  background: #ffffff;
  -webkit-box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.1);
  transition: top 1s ease-out 0.5s;
}
.header__inner {
  margin: 0 auto;
  width: 1100px;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  -webkit-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.header__logo {
  display: flex;
  flex-direction: row;
  width: 380px;
}
.header__logo .logo {
  width: 65px;
  height: 65px;
  padding-top: 5px;
  margin: 0 20px;
}
.header__logo .logo .img {
  width: 65px;
  height: 65px;
}
.header__logo .Pletnev {
  padding-top: 10px;
  font-size: 30px;
  font-weight: 600;
}
.header__logo .slogan {
  font-size: 19px;
}
.header__menu {
  flex: 1 1 300px;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.header__menu__item {
  height: 100%;
  max-width: 150px;
  line-height: 30px;
  text-align: left;
  cursor: pointer;
}
.header__menu__item a {
  display: block;
  width: 100%;
  padding-left: 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
}
.header__menu__item a::before {
  content: "";
  padding-right: 5px;
  width: 5px;
  border-left: 3px solid #fff;
  border-image: linear-gradient(to top, #fff, #fff) 1;
  height: 0%;
  transition: all 0.5s;
}
.header__menu__item a:hover::before {
  content: "";
  border-left: 3px solid #fa2424;
  border-image: linear-gradient(to top, #fa2424, #333333) 1;
}
.header__menu__item a:hover {
  height: 50%;
}
.header__menu__item a:hover > span {
  width: 100%;
  height: 3px;
  border-bottom: 3px solid #333333;
  border-image: linear-gradient(to left, #333333, #fa2424) 1;
}
.header__menu__itemUnderline {
  display: block;
  width: 0;
  height: 3px;
  border-bottom: 3px solid #fff;
  border-bottom-left-radius: 20px 20px;
  height: 5px;
  color: #fa2424;
  transition: width 0.5s;
}
.header__menuMobile {
  position: absolute;
  padding-top: 30px;
  background-color: hsla(0, 0%, 0%, 0.7);
  backdrop-filter: blur(7px);
  width: 100vw;
  height: 100vh;
  top: 70px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  transition: top 1s ease-out 0.5s;
  z-index: 900;
}
.header__menuMobile__item {
  text-align: center;
  flex: 0 0 20px;
  width: 100%;
  line-height: 30px;
  cursor: pointer;
}
.header__menuMobile__item a {
  color: #ffffff;
  font-size: 32px;
  font-weight: 400;
}
.header__menuMobile__phoneMob {
  max-width: 200px;
}
.header__menuMobile__phoneMob a {
  text-decoration: none;
  color: #ffffff;
}
.header__phone {
  max-width: 200px;
  line-height: 26px;
  color: #333333;
}
.header__phone a {
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: inherit;
}
.header__btn {
  border: 3px solid transparent;
  border-radius: 15px;
  color: #333333;
  padding: 0;
  background: #ffffff;
  animation-duration: 3s;
  animation-name: changeColor;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes changeColor {
  from {
    border-image: linear-gradient(to top, #fa2424, #333333) 0.1;
  }
  50% {
    border-image: linear-gradient(to top, #fa2424, #fa2424) 0.1;
  }
  to {
    border-image: linear-gradient(to top, #333333, #fa2424) 0.1;
  }
}
.header__btn span {
  font-size: 24px;
  font-weight: 600;
}
.header__burger .burger {
  width: 55px;
  height: 55px;
  background-size: cover;
  cursor: pointer;
  transform: scale(-1, 1);
}
@media (max-width: 1199px) {
  .header .header__inner {
    justify-content: space-around;
    width: 90%;
  }
  .header .header__menu {
    width: 100%;
    background: #ffffff;
  }
}

@media (max-width: 5000px) {
  .header {
    left: 290px;
    width: calc(100% - 290px);
  }
}
@media (max-width: 1500px) {
  .header {
    left: 0px;
    width: 100%;
  }
}
@media (max-width: 721px) {
  .header {
    left: 0px;
    width: 100%;
  }
  .header__logo {
    width: 250px;
  }
  .header .logo {
    width: 50px;
    height: 50px;
    margin: 0 5px;
  }
  .header .logo .img {
    padding-top: 10px;
    width: 45px;
    height: 45px;
  }
  .header .Pletnev {
    font-size: 15px;
  }
  .header .slogan {
    font-size: 12px;
  }
  .header__phone a {
    font-size: 15px;
  }
  .header__btn {
    width: 100px;
  }
  .header__btn span {
    font-size: 15px;
    line-height: 17px;
  }
  .header__burger .burger {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 500px) {
  .header__phone {
    display: none;
  }
  .header__btn {
    width: 200px;
    border: #fff solid 2px;
    color: #fff;
    background-color: hsla(0, 0%, 0%, 0.1);
  }
  .header__btn span {
    font-size: 15px;
    line-height: 17px;
  }
}